import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from '../components/Layout'
import PageBody from '../components/PageBody'
import FeatureCard from '../components/FeatureCard'
// import WithSubnavigation from "../components/NavBar";

// styles

// markup

const HomePageTemplate = (props)=> {
  return (
    <div>
      <PageBody content={<FeatureCard {...props} />} />
    </div>
  );
}

const HomePage = () => {
  const {directus} = useStaticQuery(pageQuery);
  const page = directus.homePage;
  return (
    <Layout meta={page.defaultSeo || false} title={page.title || false}>
      <HomePageTemplate {...page} />
    </Layout>
  );
};

const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage {
    directus {
      homePage {
        title
        subTitle
        featureContent
        featureImage {
          id
        }
        defaultSeo {
          metaTitle
          metaImage {
            id
          }
          metaDescription
        }
      }
    }
  }
`;

export default HomePage;
